const TenantImagesTypes = {
  ALTERNATE_LOGO: 'alternate_logo',
  AVATAR: 'avatar',
  BACKGROUND: 'background',
  BOT: 'bot',
  FAVICON: 'favicon',
  LOGO: 'logo',
  META: 'meta',
  WIDGET: 'widget',
};

export default TenantImagesTypes;
