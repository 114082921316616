import { useEffect, useState } from 'react';
import { fromJS } from 'immutable';
import FileUtils from '../../v1/utils/FileUtils';
import TenantImagesTypes from '../../v1/utils/TenantImageTypes';
import {
  fetchTenantImages,
  removeTenantImage,
  uploadTenantImage,
} from '../../globals/commonAPICalls';

const useTenantImagesHook = () => {
  const [tenantImages, setImages] = useState(new Map());
  const [deleteImagesList, setDeletedImage] = useState(new Set());
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getFileName = (currentImage) =>
    FileUtils.getFileNameFromUrl(
      currentImage?.image ? currentImage?.image : currentImage?.svg ?? ''
    );

  const convertIntoAMap = (array) => {
    return array.reduce((accumulator, currentElement) => {
      if (currentElement) {
        accumulator.set(currentElement.type, {
          ...currentElement,
          name: getFileName(currentElement),
        });
      }
      return accumulator;
    }, new Map());
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    const fetchData = async () => {
      try {
        const { body } = await fetchTenantImages();
        const images = body.results;
        if (isMounted) {
          const processedImages = images.reduce(
            (accumulator, currentElement) => {
              if (currentElement) {
                accumulator.set(currentElement.type, {
                  ...currentElement,
                  name: getFileName(currentElement),
                });
              }
              return accumulator;
            },
            new Map()
          );

          setImages(processedImages);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const textToSVG = (text) =>
    new Promise((resolve) => {
      const blob = new Blob([text], {
        encoding: 'UTF-8',
        type: 'image/svg+xml',
      });

      resolve(blob);
    });

  const updateTenantImages = async (newImagesList) => {
    setLoading(true);
    try {
      //  get tenant images from API to get IDs
      const { body } = await fetchTenantImages();
      const imagesOnAPI = convertIntoAMap(body.results);

      const getStoreImages = (images) => {
        const imagesToBeReturned = [];
        images.forEach((value) => {
          imagesToBeReturned.push(fromJS(value));
        });
        return imagesToBeReturned;
      };

      // remove deleted images
      for (const imageType of deleteImagesList) {
        const tenantImageID = imagesOnAPI?.get(imageType)?.id;
        await removeTenantImage(tenantImageID);
        tenantImages.delete(imageType);
        delete imagesOnAPI?.get(imageType)?.id;
      }

      // iterate over selected images
      for (const imageType in newImagesList) {
        const image = newImagesList[imageType];
        const isNewImage = Boolean(image?.id) === false && image;
        if (isNewImage) {
          const tenantImageID = imagesOnAPI?.get(imageType)?.id;
          const shouldRemoveImage = Boolean(tenantImageID);
          if (shouldRemoveImage) {
            await removeTenantImage(tenantImageID);
          }

          try {
            let newImage;
            if (imageType === TenantImagesTypes.BOT) {
              const svg = await textToSVG(image);

              newImage = await uploadTenantImage(
                svg,
                imageType,
                'chatbot_icon.svg'
              );
            } else if (imageType === TenantImagesTypes.WIDGET) {
              newImage = await uploadTenantImage(
                image,
                imageType,
                'widget_icon.png'
              );
            } else {
              newImage = await uploadTenantImage(image, imageType);
            }

            const currentImage = newImage.body;
            // update/add image to our image map
            tenantImages.set(imageType, {
              ...newImage.body,
              name: getFileName(currentImage),
            });
          } catch (error) {
            // eslint-disable-next-line no-console -- for debugging purposes
            console.error(error);
          }
        }
      }
      // reset delete set
      setDeletedImage(new Set());
      // update images
      setImages(tenantImages);
      // reset loading
      setLoading(false);

      return getStoreImages(tenantImages);
    } catch (error) {
      setError(error);
      setLoading(false);
      return error;
    }
  };

  return {
    areImagesLoading: isLoading,
    deleteImagesList,
    imagesError: error,
    setDeletedImage,
    tenantImages,
    updateTenantImages,
  };
};

export default useTenantImagesHook;
