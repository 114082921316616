import React, { useCallback, useContext } from 'react';
import {
  useChatBotIcon,
  useMutateTenant,
  useTenantImagesHook,
} from '../../../../../../customHooks';

import BrandingContent from './BrandingContent';
import CustomBrandingPageController from './CustomBrandingPageController';
import { Formik } from 'formik';
import GlobalContext from '../../../../../../globals/GlobalContext';
import PropTypes from 'prop-types';
import TenantImagesTypes from '../../../../../utils/TenantImageTypes';

const propTypes = {
  chatBotSvgImage: PropTypes.string,
  isCustomBrandingEnabled: PropTypes.bool,
  sendToastNotification: PropTypes.func.isRequired,
  updateTenantStore: PropTypes.func.isRequired,
  usersAvatar: PropTypes.string,
};

const CustomBrandingPage = ({
  chatBotSvgImage,
  updateTenantStore,
  sendToastNotification,
  isCustomBrandingEnabled = false,
  usersAvatar,
}) => {
  const {
    tenant: { tenantInfo, isTenantLoading, tenantError },
  } = useContext(GlobalContext);

  const mutateTenant = useMutateTenant();

  const {
    areImagesLoading,
    deleteImagesList,
    setDeletedImage,
    tenantImages,
    updateTenantImages,
    imagesError,
  } = useTenantImagesHook();

  const { chatBotIconSVG, resetChatBotIcon, setNewChatBotIcon } =
    useChatBotIcon(isCustomBrandingEnabled, chatBotSvgImage);

  const isLoading = isTenantLoading || areImagesLoading;

  const onSubmit = useCallback(
    async ({
      name,
      chatBotName,
      applicationLogo,
      primaryColor,
      backgroundImage,
      logInPageLogo,
      customColor,
      chatBotIcon,
      favicon,
      widgetIcon,
    }) => {
      const newTenantConfigValues = {
        branding_obj: {
          chatbot_name: chatBotName,
          color_primary: primaryColor ? primaryColor : null,
          header_color: customColor ? customColor : null,
        },
        name: name,
      };

      const newTenantImageValues = {
        [TenantImagesTypes.LOGO]: applicationLogo ?? null,
        [TenantImagesTypes.BACKGROUND]: backgroundImage ?? null,
        [TenantImagesTypes.ALTERNATE_LOGO]: logInPageLogo ?? null,
        [TenantImagesTypes.BOT]: chatBotIcon ?? null,
        [TenantImagesTypes.FAVICON]: favicon,
        [TenantImagesTypes.WIDGET]: widgetIcon ?? null,
      };

      if (!chatBotIcon) {
        resetChatBotIcon();
      } else {
        if (typeof chatBotIcon === 'string') {
          setNewChatBotIcon(chatBotIcon);
        }
      }

      try {
        await mutateTenant.mutate({
          data: newTenantConfigValues,
          tenantID: tenantInfo.id,
        });

        const updatedTenantImages = await updateTenantImages(
          newTenantImageValues
        );

        updateTenantStore(
          { ...tenantInfo, ...newTenantConfigValues },
          updatedTenantImages
        );

        if (tenantError) {
          sendToastNotification(tenantError.message, 'Error', 'error');
        } else if (imagesError) {
          sendToastNotification(imagesError.message, 'Error', 'error');
        } else {
          sendToastNotification(
            'Tenant has been updated',
            'Success',
            'success'
          );
        }
      } catch (error) {
        sendToastNotification(error.message, 'Error', 'error');
      }
    },
    [
      imagesError,
      mutateTenant,
      tenantInfo,
      updateTenantStore,
      sendToastNotification,
      updateTenantImages,
      tenantError,
      resetChatBotIcon,
      setNewChatBotIcon,
    ]
  );
  const render = useCallback(
    (props) => (
      <BrandingContent
        {...props}
        chatBotIconSVG={chatBotIconSVG}
        deleteImagesList={deleteImagesList}
        isCustomBrandingEnabled={isCustomBrandingEnabled}
        isLoading={isLoading}
        readOnlyValues={{
          applicationLogo: tenantImages?.get(TenantImagesTypes.LOGO) ?? null,
          backgroundImage:
            tenantImages?.get(TenantImagesTypes.BACKGROUND) ?? null,
          chatBotIcon: tenantImages?.get(TenantImagesTypes.BOT) ?? '',
          chatBotIconSVG: chatBotIconSVG,
          chatBotName: tenantInfo?.branding_obj?.chatbot_name ?? '',
          customColor: tenantInfo?.branding_obj?.header_color ?? '',
          customColorCheckbox: Boolean(tenantInfo?.branding_obj?.header_color),
          favicon: tenantImages?.get(TenantImagesTypes.FAVICON) ?? null,
          logInPageLogo:
            tenantImages?.get(TenantImagesTypes.ALTERNATE_LOGO) ?? null,
          name: tenantInfo?.name ?? '',
          primaryColor: tenantInfo?.branding_obj?.color_primary ?? '',
          widgetIcon: tenantImages?.get(TenantImagesTypes.WIDGET) ?? null,
        }}
        setDeletedImage={setDeletedImage}
        usersAvatar={usersAvatar}
      />
    ),
    [
      isLoading,
      setDeletedImage,
      deleteImagesList,
      isCustomBrandingEnabled,
      chatBotIconSVG,
      usersAvatar,
      tenantInfo?.branding_obj,
      tenantInfo?.name,
      tenantImages,
    ]
  );

  const initialValues = {
    applicationLogo: tenantImages?.get(TenantImagesTypes.LOGO) ?? null,
    backgroundImage: tenantImages?.get(TenantImagesTypes.BACKGROUND) ?? null,
    chatBotIcon: tenantImages?.get(TenantImagesTypes.BOT) ?? '',
    chatBotName: tenantInfo?.branding_obj?.chatbot_name ?? '',
    customColor: tenantInfo?.branding_obj?.header_color ?? '',
    customColorCheckbox: Boolean(tenantInfo?.branding_obj?.header_color),
    favicon: tenantImages?.get(TenantImagesTypes.FAVICON) ?? null,
    logInPageLogo: tenantImages?.get(TenantImagesTypes.ALTERNATE_LOGO) ?? null,
    name: tenantInfo?.name ?? '',
    primaryColor: tenantInfo?.branding_obj?.color_primary ?? '',
    widgetIcon: tenantImages?.get(TenantImagesTypes.WIDGET) ?? null,
  };

  const validate = useCallback((values) => {
    const errors = {};

    const hexadecimalError = 'Invalid hexadecimal value';
    const onlyOneHexadecimalPerLineRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

    if (
      values.customColor &&
      !onlyOneHexadecimalPerLineRegex.test(values.customColor)
    ) {
      errors.customColor = hexadecimalError;
    }
    if (
      values.primaryColor &&
      !onlyOneHexadecimalPerLineRegex.test(values.primaryColor)
    ) {
      errors.primaryColor = hexadecimalError;
    }

    return errors;
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={render}
      validate={validate}
    />
  );
};

CustomBrandingPage.propTypes = propTypes;

export default CustomBrandingPageController(CustomBrandingPage);
