const DESCRIPTION_LABELS = {
  APPLICATION_LOGO_DESCRIPTION:
    'Logo is used for branding across the whole application.',
  BACKGROUND_IMAGE_DESCRIPTION:
    'Background image will show on all login and onboard workflow pages when a user is outside of the main application.',
  BOT_IDENTITY_BRANDING_DESCRIPTION:
    'Customizations here apply to the web and mobile applications only. To customize an integration please reach out to your customer success representative.',
  CHAT_BOT_ICON_DESCRIPTION:
    'Icon/Logo must be in SVG format. Paste in SVG code into text box above.',
  CUSTOMIZE_HEADER_COLOR_DESCRIPTION:
    'Header color for the web application. If no color is specified the primary color above will be used.',
  LOG_IN_PAGE_LOGO_DESCRIPTION:
    'Log in page logo is used to define an image to display on the login screen or in workflows whenever an image background is present. It is not required.',
  NAME_DESCRIPTION:
    'Name will show up in workflows, transactional emails, and SMS messages.',
  PRIMARY_COLOR_DESCRIPTION:
    'Primary color will be applied throughout the user interface.',
  WIDGET_ICON_DESCRIPTION: 'Icon must be in PNG format.',
};

const INPUT_LABELS = {
  APPLICATION_LOGO: 'Application Logo',
  BACKGROUND_IMAGE: 'Background Image (Optional)',
  CANCEL: 'Cancel',
  CHAT_BOT_ICON_LOGO: 'ChatBot Icon/Logo',
  CHAT_BOT_NAME: 'ChatBot Name',
  CUSTOMIZE_HEADER_COLOR: 'Customize Header Color',
  FAVICON: 'Favicon',
  LOG_IN_PAGE_LOGO: 'Log In Page Logo (Optional)',
  NAME: 'Name',
  PRIMARY_COLOR: 'Primary Color',
  SAVE: 'Save Changes',
  WIDGET_ICON_LOGO: 'Widget Icon',
};

const TITLES = {
  APPLICATION_IDENTITY_BRANDING: 'Application Identity & Branding',
  BOT_IDENTITY_BRANDING: 'Bot Identity & Branding',
  COLORS: 'Colors',
  IMAGES: 'Images',
};

const utils = {
  DESCRIPTION_LABELS,
  INPUT_LABELS,
  TITLES,
};

export { DESCRIPTION_LABELS, INPUT_LABELS, TITLES };

export default utils;
