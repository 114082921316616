import React from 'react';
import pt from 'prop-types';
import { Header } from 'semantic-ui-react';
import { Icon } from '@iconify/react';

const propTypes = {
  iconImage: pt.string,
  iconObject: pt.exact({
    body: pt.string,
    height: pt.number,
    width: pt.number,
  }),
};

const BrandingContentWidgetIconPreview = ({ iconImage, iconObject }) => (
  <>
    <Header as='h3' content='Widget Icon Preview' />
    <p>
      Widget icon as it will appear when the widget is added to a page. If no
      custom widget icon is set, it will use the custom bot icon. If no custom
      bot icon is set, it will use the default Barista icon.
    </p>
    <div>
      <div
        style={{
          border: '1px solid #ddd',
          borderRadius: '50%',
          height: '64px',
          overflow: 'hidden',
          width: '64px',
        }}
      >
        {iconImage ? (
          <img
            alt='Widget Icon Preview'
            src={iconImage}
            style={{
              height: '100%',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        ) : (
          iconObject && (
            <Icon
              className='primary icon'
              height='100%'
              icon={iconObject}
              width='100%'
            />
          )
        )}
      </div>
    </div>
  </>
);

BrandingContentWidgetIconPreview.propTypes = propTypes;

export default BrandingContentWidgetIconPreview;
