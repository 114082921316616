import APIcall from '../../v1/utils/APIcall';
import endpointGenerator from '../../v1/utils/endpointGenerator';

const uploadTenantImage = (image, type, filename) => {
  const data = new FormData();

  data.append('type', type);
  if (image.type === 'image/svg+xml') {
    data.append('svg', image, filename);
  } else {
    data.append('image', image, filename);
  }

  return APIcall.post({
    data,
    token: true,
    url: endpointGenerator.genPath('espImage.tenantImages'),
  });
};

export default uploadTenantImage;
